<script>
export default {
  name: "SalesAccountTable",
  props: {
    salesAccountList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      table: {
        fields: [
          { key: "nik" },
          { key: "fullname", class: "text-left" },
          { key: "username", class: "text-left" },
          { key: "joinDate", class: "text-right" },
          { key: "status", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        sortBy: "username"
      }
    };
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-table
        :items="salesAccountList"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        thead-class="text-primary"
        empty-text="No data to show"
        striped
        hover
        show-empty
        fixed
      >
        <template #cell(joinDate)="data">
          <div>{{ data.item.joinDate | date }}</div>
        </template>
        <template v-slot:cell(status)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div>
            <b-button class="btn btn-sm btn-wide btn-info" @click="data.item" :disabled="true">Edit</b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
