<script>
import ApiService from "@services/api.service";
import SalesAccountTable from "./components/SalesAccountTable";

export default {
  name: "SalesAccountList",
  components: {
    SalesAccountTable
  },
  data() {
    return {
      table: {
        fields: [
          { key: "nik" },
          { key: "fullname" },
          { key: "role", label: "Role" },
          { key: "uplineCode", label: "Upline" },
          { key: "target" },
          { key: "date", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: null,
        pageListOptions: [10, 20, 50, 100],
        paging: {
          pageNo: 1,
          pageSize: 10,
          recordCount: 0
        }
      },

      parameter: {
        hierarchyList: []
      },

      loader: {
        table: false,
        uploadButton: false,
        downloadButton: false
      },

      filter: {
        code: null,
        fullname: null,
        hierarchyCode: null,
        isActive: null
      },

      salesAccount: {
        code: null, //
        username: null, //
        fullname: null, //
        dob: null, //
        joinDate: null, //
        resignDate: null, //
        positionCode: null, //,
        description: null,
        wFOTarget: 0,
        wFHTarget: 0,
        branchCode: null
      },

      edit: false,
      file: null,
      error: null,
      successCount: null,
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data ? this.table.data.length : null;
    }
  },
  watch: {
    "table.paging": {
      deep: true,
      handler() {
        this.loadTable();
      }
    }
  },
  created() {
    this.loadTable();
    this.loadHierarchy();
  },
  methods: {
    loadTable() {
      this.loader.table = true;
      let payload = {
        ...this.filter,
        paging: this.table.paging
      };
      ApiService.post("salesaccount/paged", payload)
        .then(resp => {
          this.table.data = resp.records;
          this.table.paging.recordCount = resp.recordCount;
        })
        .finally(() => {
          this.loader.table = false;
        });
    },
    loadHierarchy() {
      this.loader.table = true;
      ApiService.get("/Hierarchy")
        .then(resp => {
          this.parameter.hierarchyList = resp;
        })
        .finally(() => {
          this.loader.table = false;
        });
    },
    uploadTemplate() {
      this.loader.uploadButton = true;
      this.resetData();
      let formData = new FormData();
      formData.append("file", this.file);
      ApiService.upload("salesaccount/template", formData)
        .then(resp => {
          this.showToast("success", "Upload success");
          this.loadTable();
          this.successCount = resp;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.loader.uploadButton = false;
          this.file = null;
        });
    },
    downloadTemplate() {
      this.loader.downloadButton = true;
      ApiService.download("salesaccount/template")
        .then(resp => {
          this.download(resp.data, "SalesAccountTemplate.xlsx");
        })
        .catch(err => this.showToast(err))
        .finally(() => {
          this.loader.downloadButton = false;
        });
    },
    resetData() {
      this.successCount = null;
      this.error = null;
    },
    openDetail(salesCode) {
      this.changeLoader(true);
      ApiService.get("SalesAccount/detail", { code: salesCode })
        .then(resp => {
          this.edit = true;
          this.salesAccount = resp;
          this.showModal = true;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    getRowVariant(item, type) {
      if (!item || type !== "row") return;
      if (!item.isActive) return "table-danger";
    },
    saveUserAccount() {
      if (!this.edit) {
        this.changeLoader(true);
        ApiService.post("salesAccount", this.salesAccount)
          .then(async () => {
            await this.loadTable();
            this.showToast("success", "Create Successful");
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("salesAccount", this.salesAccount)
          .then(async () => {
            await this.loadTable();
            this.showToast("success", "Update Successful");
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggleActive(code) {
      this.showConfirm("Are you sure want to do this?").then(resp => {
        if (resp) {
          this.changeLoader(true);
          let qs = {
            code: code
          };
          ApiService.patch("/SalesAccount", null, qs)
            .then(() => {
              this.showToast("success", "Toggle Successfull");
              this.loadTable();
            })
            .catch(err => {
              this.showToast("error", err);
            })
            .finally(() => {
              this.changeLoader(false);
            });
        }
      });
    },
    clearForm() {
      this.salesAccount = {
        code: null, //
        username: null, //
        fullname: null, //
        dob: null, //
        joinDate: null, //
        resignDate: null, //
        positionCode: null, //
        description: null,
        wFOTarget: 0,
        wFHTarget: 0,
        branchCode: null
      };
      this.edit = false;
    },
    downloadUser() {
      this.loader.downloadButton = true;
      ApiService.download("SalesAccount/export")
        .then(resp => {
          this.download(resp.data, "SalesAccountList.xlsx");
        })
        .catch(err => this.showToast(err))
        .finally(() => {
          this.loader.downloadButton = false;
        });
    },
    async resetPassword(salesCode) {
      if (await this.showConfirm("Confirm to reset password to User's DOB ?")) {
        this.changeLoader(true);
        ApiService.put("salesAccount/password", null, { salesAccountCode: salesCode })
          .then(() => {
            this.showToast("success", "Password Reset Success.");
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-button v-b-toggle.filterForm variant="outline-primary" block>Filter Panel</b-button>
      <b-collapse id="filterForm" visible class="mt-2">
        <b-row>
          <b-col xl="3" lg="4">
            <b-form-group label="NIK">
              <b-input v-model="filter.code" type="number" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4">
            <b-form-group label="Full Name">
              <b-input v-model="filter.fullname" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4">
            <b-form-group label="Level">
              <b-form-select
                v-model="filter.hierarchyCode"
                class="form-control"
                value-field="code"
                text-field="description"
                :options="parameter.hierarchyList"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Select All --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="4">
            <b-form-group label="Status">
              <b-form-select
                v-model="filter.isActive"
                class="form-control"
                value-field="value"
                text-field="description"
                :options="[
                  { value: true, description: 'Active' },
                  { value: false, description: 'Inactive' }
                ]"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Select All --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-btn variant="primary" @click.stop="loadTable">Filter</b-btn>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
    <b-card>
      <div class="d-flex mb-2 align-items-center">
        <b-button variant="outline-primary" class="mr-1" @click.stop="showModal = true">Create</b-button>
        <b-form-file
          v-model="file"
          style="width: 300px"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Upload User Batch Template..."
          accept=".xlsx"
        />
        <b-button
          v-if="file"
          class="ml-2 d-flex align-items-center"
          variant="outline-info"
          :disabled="loader.uploadButton"
          @click.stop="uploadTemplate"
        >
          <b-spinner v-if="loader.uploadButton" variant="primary" label="Spinning" small class="mr-1" />
          <span>Upload</span>
        </b-button>

        <b-button class="ml-auto d-flex align-items-center" variant="outline-success" :disabled="loader.downloadButton" @click="downloadTemplate()">
          <b-spinner v-if="loader.downloadButton" variant="primary" label="Spinning" small class="mr-1" />
          Download Template
        </b-button>
        <b-btn variant="success" class="ml-1" :disabled="loader.downloadButton" @click.stop="downloadUser"
          ><b-spinner v-if="loader.downloadButton" variant="primary" label="Spinning" small class="mr-1" /> Export Data
        </b-btn>
        <v-select
          v-model="table.paging.pageSize"
          class="ml-2"
          placeholder="Record Per Page"
          :clearable="false"
          :options="table.pageListOptions"
        ></v-select>
      </div>

      <b-card v-if="successCount" title="You Have Uploaded Sales Account Successfully" border-variant="success" bg-variant="transparent">
        <hr />
        <h3>Record Count : {{ successCount }}</h3>
      </b-card>

      <b-card
        v-if="error"
        title="You have errors on your file."
        sub-title="Please check errors detail below."
        border-variant="danger"
        bg-variant="transparent"
      >
        <hr />
        <pre class="text-danger">{{ error }}</pre>
      </b-card>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :busy.sync="loader.table"
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
        responsive="xl"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(nik)="data">
          <div>
            <div>{{ data.item.code }}</div>
            <div>
              <i>{{ data.item.username }}</i>
            </div>
          </div>
        </template>

        <template #cell(role)="data">
          <div>
            <div>{{ data.item.hierarchyDesc }}</div>
            <div>
              <i>{{ data.item.description }}</i>
            </div>
          </div>
        </template>

        <template #cell(uplineCode)="data">
          <b-button v-if="data.item.uplineCode" variant="link" @click.stop="openDetail(data.item.uplineCode)">
            <div>{{ data.item.uplineCode }}</div>
          </b-button>
        </template>

        <template #cell(target)="data">
          <div>
            <div v-if="data.item.wFHTarget > 0">
              WFH : <b>{{ data.item.wFHTarget }}</b>
            </div>
            <div v-if="data.item.wFOTarget > 0">
              WFO : <b>{{ data.item.wFOTarget }}</b>
            </div>
          </div>
        </template>

        <template #cell(date)="data">
          <div v-if="data.item.joinDate">Join : {{ data.item.joinDate | date }}</div>
          <div v-if="data.item.resignDate">Resign : {{ data.item.resignDate | date }}</div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown right text="Action" variant="outline-primary">
            <b-dropdown-item :variant="data.item.isActive ? `danger` : `success`" @click.stop="toggleActive(data.item.code)">{{
              data.item.isActive ? `Deactivate` : `Activate`
            }}</b-dropdown-item>
            <b-dropdown-item variant="primary" @click.stop="openDetail(data.item.code)">Detail</b-dropdown-item>
            <b-dropdown-item variant="warning" @click.stop="resetPassword(data.item.code)">Reset Password</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="d-flex mt-2">
        <span class="mr-auto">
          Showing of
          <span class="font-weight-bold">{{ rowCount }}</span>
          of
          <span class="font-weight-bold">{{ table.paging.recordCount }}</span>
        </span>
        <b-pagination
          v-model="table.paging.pageNo"
          class="ml-auto"
          :total-rows="table.paging.recordCount"
          :per-page="table.paging.pageSize"
          align="center"
        ></b-pagination>
      </div>
    </b-card>

    <b-modal v-model="showModal" centered size="lg" hide-footer :backdrop="true" title="Create User" @hidden="clearForm">
      <b-form @submit.stop.prevent="saveUserAccount">
        <b-row>
          <b-col md="6">
            <b-form-group label="NIK">
              <b-input v-model="salesAccount.code" type="number" class="form-control" :disabled="edit" :class="edit ? 'bg-gray' : ''" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Level">
              <v-select v-model="salesAccount.hierarchyCode" label="description" :reduce="x => x.code" :options="parameter.hierarchyList"></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Branch Code">
              <b-input v-model="salesAccount.branchCode" class="form-control"></b-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Position">
              <b-input v-model="salesAccount.description" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Upline NIK">
              <b-input v-model="salesAccount.uplineCode" type="number" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Full Name">
              <b-input v-model="salesAccount.fullname" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date of Birth">
              <b-form-datepicker v-model="salesAccount.dob"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Join Date">
              <b-form-datepicker v-model="salesAccount.joinDate" reset-button></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Resign Date">
              <b-form-datepicker v-model="salesAccount.resignDate" reset-button></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="WFO Target">
              <b-form-input v-model="salesAccount.wFOTarget" type="number" class="form-control" />
              <small>*If this position doesnt have target, leave it empty</small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="WFH Target">
              <b-form-input v-model="salesAccount.wFHTarget" type="number" class="form-control" />
              <small>*If this position doesnt have target, leave it empty</small>
            </b-form-group>
          </b-col>
        </b-row>
        <span class="font-weight-bold">
          Default password for new user is user's dob. with format (ddmmyy).
          <br />
          Example: if dob user 22 April 2021, then the password is 220421
        </span>

        <b-row class="mt-5">
          <b-col>
            <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
          </b-col>
          <b-col>
            <b-btn block type="submit" variant="success">Save</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
